function createGuide(translations, base) {
	let guide = [];
	if (!translations || !base) return;
	Array.from(Object.keys(translations)).forEach((key) => {
		if (key.includes(base)) {
			guide.push({
				key: key,
				contentKey: key.replace("guide_title", "paragraph"),
			});
		}
	});
	return guide;
}
function createNavigation(guideId) {
	let guide = document.getElementById(guideId);
	if (!guide) return;
	let links = Array.from(guide.getElementsByTagName("li"));
	if (!links || !links.length) return;
	links.forEach((link) => {
		let anchor = link.id;
		link.onclick = (event) => {
			event.preventDefault();
			let paragraph = document.querySelector("." + anchor);
			paragraph.scrollIntoView({ block: "start", behavior: "smooth" });
		};
	});
}

export { createGuide, createNavigation };
